.app {
    padding: 0.5em;
    width: $AppViewWidth;
    height: $AppViewHeight;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    & > .app__icon {
        display: flex;
        width: 300px;
        height: 64px;
        max-width: 300px;
        max-height: 64px;
        min-width: 300px;;
        min-height: 64px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url($Fruits);
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }
}

.app-driver {
    padding: 0.5em;
    width: $AppViewWidth;
    height: $AppViewHeight;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    & > .app__icon {
        display: flex;
        width: 300px;
        height: 40px;
        max-width: 300px;
        max-height: 64px;
        min-width: 300px;;
        min-height: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($Fruits);
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }
}

.app-kitchen {
    padding: 0.5em;
    width: $AppViewWidth;
    height: $AppViewHeight;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    & > .app__icon {
        display: flex;
        width: 300px;
        height: 40px;
        max-width: 300px;
        max-height: 64px;
        min-width: 300px;;
        min-height: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($Fruits);
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }
}

.app-manager {
    padding: 0.5em;
    width: $AppViewWidth;
    height: $AppViewHeight;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    & > .app__icon {
        display: flex;
        width: 300px;
        height: 40px;
        max-width: 300px;
        max-height: 64px;
        min-width: 300px;;
        min-height: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($Fruits);
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }
}


.ui.fluid.dropdown.shadowed-dropdown > div.text {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.ui.fluid.dropdown.shadowed-dropdown > .dropdown.icon:before {
   display: none; 
}

.ui.fluid.dropdown.shadowed-dropdown {
    padding-top: 0.33em;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    border-radius: .28571429rem;
}


.ui.table tr.menu-lunch {
    background-color: rgb(238, 250, 252);
}
.ui.table tr.menu-tea {
    background-color: rgba(209, 220, 228, 0.418);
}

.ui.table tr.alternative_odd {
    background-color: rgb(238, 250, 252);
}
.ui.table tr.alternative_even {
    background-color: rgba(209, 220, 228, 0.418);
}

.ui.table tr.jogger_odd {
    background-color: rgb(253, 253, 253);
}
.ui.table tr.jogger_even {
    background-color: rgb(232, 238, 238);
}

.ui.table td.pdf-cycle1 {
    background-color: #E0F7FA;
}
.ui.table td.pdf-cycle2 {
    background-color: #ECEFF1;
}
.ui.table td.pdf-cycle3 {
    background-color: #E0F7FA;
}


// background-color: #EC8C33;
// .ui.fluid.raised.card.content.menu-card__top {
.ui.card.content.menu-card__top {
    background-color: #96BF4C;
}

.ui.fluid.raised.card.menu-card__middle {
    background-color: #EC8C33;
}

.ui.fluid.raised.card.menu-card__bottom {
    background-color: #FBE741;
}

.ui.basic.label.menu-card__lunch {
    background-color: #FBE741;
}

.ui.basic.label.menu-card__meal-item {
    background-color: #FBE741;
    margin-top: 1px;
}

.ui.basic.label.jogger__meal-item {
    background-color: rgb(238, 245, 244);
    margin-top: 1px;
}

.ui.basic.label.jogger__menu-item {
    background-color: rgb(238, 245, 244);
    border-color: darkblue;
}

.ui.basic.label.jogger__meal-total {
    background-color: rgb(250, 221, 212);
    margin-top: 1px;
}

.ui.basic.label.jogger__meal-dietary--vegetarian_meal {
    background-color: rgb(36, 177, 31);
    color: white;
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--main_dietary {
    background-color: rgb(221, 67, 67);
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--vegan_dietary {
    background-color: rgb(243, 236, 169);
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--halal_dietary {
    background-color: rgb(84, 177, 240);
    color: white;
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--special_dietary {
    background-color: rgb(145, 69, 148);
    color: white;
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--side_alternative {
    background-color: rgb(221, 161, 71);
    margin-top: 1px;
}

.ui.basic.label.jogger__meal-dietary--vegetarian_meal_with_side {
    background-color: rgb(3, 95, 23);
    color: white;
    // background-image: linear-gradient(to right,rgb(36, 177, 31), rgb(192, 224, 191));
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--main_dietary_with_side {
    background-color: rgb(87, 25, 25);
    color: white;
    // background-image: linear-gradient(to right, rgb(221, 67, 67), rgb(214, 188, 188));
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--vegan_dietary_with_side {
    background-color: rgb(112, 54, 18);
    color: white;
    // background-image: linear-gradient(to right, rgb(243, 236, 169), rgb(243, 220, 9));
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--halal_dietary_with_side {
    background-color:rgb(44, 58, 134);
    color: white;
    // background-image: linear-gradient(to right, rgb(84, 177, 240), rgb(197, 207, 214));
    margin-top: 1px;
}
.ui.basic.label.jogger__meal-dietary--special_dietary_with_side {
    background-color: white;
    // background-image: linear-gradient(to right, rgb(145, 69, 148), rgb(229, 200, 230));
    margin-top: 1px;
}

.ui.basic.label.menu-card__tea {
    background-color:#FBE741;
}

.ui.basic.label.view-order__date {
    background-color: rgb(226, 226, 219);
}

.ui.basic.label.update-alert__updating {
    background-color:rgb(105, 208, 14);
    width: 252px;
}

.ui.basic.label.update-alert {
    background-color: rgb(253, 254, 254);
    width: 252px;
}

.ui.basic.label.update-alert__updating-small {
    background-color:rgb(105, 208, 14);
    width: 160px;
}

.ui.basic.label.update-alert-small {
    background-color: rgb(253, 254, 254);
    width: 160px;
}

.ui.basic.label.connected-status__ok {
    background-color:rgb(197, 233, 166);
}

.ui.basic.label.connected-status__bad {
    background-color:rgb(208, 14, 46);
}

.wrapped {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.log-view__header {
    display: inline-flex;
}

.app-inline-controls {
    display: inline-flex;
}

.alternatives-editor-form__portal {
    z-index: 3;
    left: 25%; 
    width: 50%;
    min-width: 400px;
    position: fixed !important;
    top: 5%;
}

.diary-list-dropdown-container {
    width: 400px;
    min-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.action-diary-list-dropdown-container {
    // width: 412px;
    // min-width: 412px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.pdf-manager-add-edit-categories__portal {
    z-index: 5;
    left: 5%; 
    width: 90%;
    position: fixed !important;
    top: 5%;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

.diary-check-add-edit-category-form__portal {
    z-index: 5;
    left: 25%; 
    width: 50%;
    min-width: 400px;
    position: fixed !important;
    top: 5%;
}

.diary-check-add-edit-form__portal {
    z-index: 5;
    left: 25%; 
    width: 50%;
    min-width: 400px;
    position: fixed !important;
    top: 5%;
}

.diary-check-add-edit-protocol-form__portal {
    z-index: 5;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    position: fixed !important;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}

.order-restriction-editor-form__portal {
    z-index: 5;
    left: 35%; 
    width: 30%;
    min-width: 300px;
    position: fixed !important;
    top: 25%;
}

.temperature-input-form__portal {
    z-index: 10;
    width: 100%;
    max-width: 400px;;
    position: fixed !important;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}

.delete-logs__portal {
    z-index: 1000;
    left: 40%; 
    width: 20%;
    position: fixed !important;
    top: 5%;
    min-height: 108px;
    min-width: 408px;
}

.menu-editor-form__portal {
    z-index: 1000;
    left: 35%; 
    width: 30%;
    min-width: 400px;
    position: fixed !important;
    top: 5%;
}

.order-view__portal {
    z-index: 1000;
    left: 10%; 
    width: 80%;
    position: fixed !important;
    top: 5%;
}

.portion-view__portal {
    z-index: 1000;
    left: 10%; 
    width: 80%;
    position: fixed !important;
    top: 5%;
}


.app-mobile-inline-controls {
    display: inline-flex;
    line-height: 2.5em;
    padding: 5px;
}

// SingleDatePicker
// When hovering over the date shows a hand icon
.DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2   {
    cursor: pointer;  
}


// .DateRangePicker.DateRangePicker_1.DateRangePicker__block.DateRangePicker__block_2 {
    
// }

// .DateRangePickerInput.DateRangePickerInput_1  {
//     height: inherit;
// }

.DateRangePickerInput.DateRangePickerInput_1  {
    border-radius: .28571429rem;
    max-width: 270px;
}

.DateInput_input{
    font-style: normal;
    text-align: center;
    font-size: 1rem;
    line-height: 1em;    
    min-height: 1em;
    outline: 0;
    border: none;
    cursor: pointer;  
    padding-top: 0.63em;
    padding-bottom: 0.65em;
}

.DateRangePickerInput_arrow_svg {
    display: none;
}

// .DateInput_input.DateInput_input_1   {
//     cursor: pointer;  
//     font-size: inherit;
// }

/*
.DateRangePicker.DateRangePicker_1.DateRangePicker__block.DateRangePicker__block_2 {

}
<div class="DateRangePicker DateRangePicker_1 DateRangePicker__block DateRangePicker__block_2"><div><div class="DateRangePickerInput DateRangePickerInput_1 DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput__block DateRangePickerInput__block_3"><div class="DateInput DateInput_1"><input class="DateInput_input DateInput_input_1" aria-label="Start Date" type="text" id="runStartId" name="runStartId" placeholder="Start Date" autocomplete="off" aria-describedby="DateInput__screen-reader-message-runStartId" value="Mon 30 Jul"><p class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1" id="DateInput__screen-reader-message-runStartId">Press the down arrow key to interact with the calendar and
  select a date. Press the question mark key to get the keyboard shortcuts for changing dates.</p></div><div class="DateRangePickerInput_arrow DateRangePickerInput_arrow_1" aria-hidden="true" role="presentation"><svg class="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" viewBox="0 0 1000 1000"><path d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path></svg></div><div class="DateInput DateInput_1"><input class="DateInput_input DateInput_input_1" aria-label="End Date" type="text" id="runEndId" name="runEndId" placeholder="End Date" autocomplete="off" aria-describedby="DateInput__screen-reader-message-runEndId" value="Fri 03 Aug"><p class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1" id="DateInput__screen-reader-message-runEndId">Press the down arrow key to interact with the calendar and
  select a date. Press the question mark key to get the keyboard shortcuts for changing dates.</p></div></div></div></div>

<div class="DateRangePickerInput DateRangePickerInput_1 DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput__block DateRangePickerInput__block_3"><div class="DateInput DateInput_1"><input class="DateInput_input DateInput_input_1" aria-label="Start Date" type="text" id="runStartId" name="runStartId" placeholder="Start Date" autocomplete="off" aria-describedby="DateInput__screen-reader-message-runStartId" value="Mon 30 Jul"><p class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1" id="DateInput__screen-reader-message-runStartId">Press the down arrow key to interact with the calendar and
  select a date. Press the question mark key to get the keyboard shortcuts for changing dates.</p></div><div class="DateRangePickerInput_arrow DateRangePickerInput_arrow_1" aria-hidden="true" role="presentation"><svg class="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" viewBox="0 0 1000 1000"><path d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path></svg></div><div class="DateInput DateInput_1"><input class="DateInput_input DateInput_input_1" aria-label="End Date" type="text" id="runEndId" name="runEndId" placeholder="End Date" autocomplete="off" aria-describedby="DateInput__screen-reader-message-runEndId" value="Fri 03 Aug"><p class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1" id="DateInput__screen-reader-message-runEndId">Press the down arrow key to interact with the calendar and
  select a date. Press the question mark key to get the keyboard shortcuts for changing dates.</p></div></div>
*/

.app-icon-inline-controls {
    display: inline-flex;
    //line-height: 2.5em;
    padding: 5px;
}

.user-icon {
    width: 114px;
    height: 36px;
    
    background-repeat: no-repeat;
    background-position: center;
    background-image: url($Fruits);
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
}

.check-item {
    display: inline-flex;
    width: 60px;
    height: 100%;
    // margin-right: 2px;

    & > .check-item_text {
        width: 32px;
        margin-right: 2px;
    }

    & > .check-item_icon {
        width: 26px;
    }
}

.alert-item {
    display: inline-flex;
    min-width: 136px;
    width: 100%;
    height: 100%;
    
    & > .alert-item_text {
        width: fit-content;
        margin-right: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > .alert-item_icon {
        width: 26px;
    }
}

.menu-label {
    padding-top: 0.357143em;
    padding-right: 0.785714em;
    padding-bottom: 0.357143em;
    padding-left: 0.485714em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.menu-label-header {
    // padding-top: 0.357143em;
    // padding-right: 0.785714em;
    padding-bottom: 0.2em;
    padding-left: 0.2em;
    font-size: 24px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

@media only screen and (max-width: 767px) {
    .ui.stackable.grid>.row>.column.menu-card-header {
        padding: 4px !important;
    }
}

.ui.search.dropdown>.text {
    margin-left: 0.642857em;
    cursor: text;
    position: relative;
    left: 1px;
    z-index: 3;
}

.ui.fluid.dropdown.menu-editor>div.text {
    margin-left: 0.242857em;
    padding-top: 0.357143em;
    padding-right: 0.785714em;
    padding-bottom: 0.357143em;
    padding-left: 0.685714em;
    background-color: #e8e8e8;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    border-radius: .28571429rem;
}

.ui.fluid.dropdown.checks {
    padding-top: 0.5em;
    padding-right: 0.78em;
    padding-bottom: 0.22em;
    padding-left: 0.22em;
    //background-color: #e8e8e8;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    border-radius: .28571429rem;
}

.ui.fluid.dropdown.checks>div.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-left: 0.22em;
}

//  stops the item checks dropdown from overlaying the amount input underneath it
.ui.fluid.dropdown.item-checks {
    height: 26px;
    & > div.text {
        height: 26px!important;
    }
}

.ui.fluid.dropdown.checks > .dropdown.icon:before {
    display: none; 
 }

 .ui.fluid.dropdown.visibility > .dropdown.icon:before {
    display: none; 
 }

.ui.fluid.dropdown.arrowed > .dropdown.icon:before {
    display: none; 
 }

 .ui.fluid.dropdown.pdf-category {
    margin-bottom: 6px;
 }

 .ui.fluid.dropdown.pdf-category > .dropdown.icon:before {
    display: none;   
 }


.ui.fluid.dropdown.custom > .dropdown.icon:before {
    display: none; 
 }

 .ui.basic.fluid.dropdown.custom > .dropdown.icon:before {
    display: none; 
 }
 
.ui.basic.fluid.search.dropdown.custom > .dropdown.icon:before {
    display: none; 
 }

 .ui.basic.fluid.dropdown.prerequisite > .dropdown.icon:before {
    display: none; 
 }
 .ui.basic.fluid.dropdown.prerequisite > .text {
    margin-left: 10px; 
 }
 

 

.field.alert-rule-type > .ui.dropdown > .text {
    margin-left: 10px; 
}

.field.alert-rule-type > .ui.dropdown>.dropdown.icon:before {
    display: none; 
}

.field.order-diaries-group > .ui.dropdown > .text {
    margin-left: 10px; 
}

.field.order-diaries-group > .ui.dropdown>.dropdown.icon:before {
    display: none; 
}


.ui.small.table {
    font-size: 1em;
    line-height: .85em;
}

.ui.small.table.compact {
    font-size: 1em;
    line-height: .85em;
}

.ui.table td {
    padding-top: 0.375em;
    padding-right: 0.785714em;
    padding-bottom: 0.375em;
    padding-left: 0.785714em;
    text-align: inherit;
}

.ui.table thead th {
    cursor: auto;
    background-color: #f9fafb;
    text-align: inherit;
    color: rgba(0,0,0,.87);
    padding-top: 0.375em;
    padding-right: 0.785714em;
    padding-bottom: 0.375em;
    padding-left: 0.785714em;
    vertical-align: inherit;
    text-transform: none;
    border-left: none;
}


.dietAlterRemoveBtn {
    border-radius: 2px;
    margin-left: 4px;
    color: rgb(15, 8, 53);
    background-color: #dee5ec;
    border: none;
}
.dietAlterRemoveBtn:hover,
.dietAlterRemoveBtn.hover {
    color: white;
    background-color: #346392;
}
.dietAlterRemoveBtn:active,
.dietAlterRemoveBtn.active {
    background-color: #27496d;
}




// .ui.table thead th {
//     cursor: auto;
//     background: #f9fafb;
//     text-align: inherit;
//     color: rgba(0,0,0,.87);
//     padding-top: 0.928571em;
//     padding-right: 0.785714em;
//     padding-bottom: 0.928571em;
//     padding-left: 0.785714em;
//     vertical-align: inherit;
//     font-style: none;
//     font-weight: 700;
//     text-transform: none;
//     border-bottom: 1px solid rgba(34,36,38,.1);
//     border-left: none;
// }


.ui.dropdown.sub-menu {
    display: flex; 
    flex-direction: column;
    width: auto;
    height: auto;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    border-top: 1px solid rgba(255,255,255,.08);
    :hover #nested-menu {
        background: rgba(255,255,255,.08);
        color: #fff;
    }
}
.ui.dropdown.sub-menu > .dropdown.icon {
    display: none; 
}
.ui.dropdown.sub-menu > .dropdown.icon:before {
    display: none; 
}
.ui.dropdown > .nested-menu {
    border: unset;
    border-radius: unset;
    border: 1px solid white;
    border-radius: 1px;
    top: 12px!important;
    right: unset;
    :hover {
        background-color: unset !important;
        color: unset !important;
    }
}

.ui.menu .ui.dropdown .nested-menu>.nested-menu-item {
    border: unset;
    border-radius: unset;
    padding: 1em 1em!important;
    color: white!important;
    background-color:rgba(17, 16, 16, .92)!important;
}

.item-Orange{
    color: black!important;
    background-color: rgb(221, 161, 71)!important;
}
.item-Green{
    color: white!important;
    background-color: rgb(36, 177, 31)!important;
}