@import "svgs";
@import "variables";
@import "mixins";
@import "../Auth/styles/master";
@import "../App/styles/master";
@import "../Common/styles/master";

body { 
    background: linear-gradient(180deg, rgb(240, 255, 240) 0%, rgb(178, 236, 224) 70%, rgb(160, 175, 238) 100%); 
    overscroll-behavior-y: contain;
}

.dont-show-this-unless-printing {
    visibility: hidden;
    height: 0px;
    margin: 0;
    padding: 0;
}

.ui.basic.segment.dont-show-this-unless-printing {
    visibility: hidden;
    height: 0px;
    margin: 0;
    padding: 0;
}

@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.refresh-content {
    margin-top: 50px;
    text-align: center;
}

.refresh-content-icon {
    display: block;
    opacity: .75;
    width: 66px;
    height: 66px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url($BeeLogo);
    animation-name: ckw;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    margin-bottom: 10px;
}

.refresh-content-text {
    font-size: 1.5em;
    font-weight: bolder;
    margin-left: auto;
    margin-right: auto;
}

.centered-fixed {
    z-index: 10;
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
}

/// https://tobiasahlin.com/blog/curved-path-animations-in-css/
.wander-bee-container {
    width: 100px;
    height: 100px;
    margin-top: 20vh;
    margin-left: auto;
    margin-right: auto;
}
.wander-bee {
    animation: xAxis 3.5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
}

.wander-bee::after {
    content: '';
    display: block;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px auto;
    background-image: url($BeeLogo);
    animation: yAxis 3.5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
}

@keyframes yAxis {
    10% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(100px);
    }
    20% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(20px);
    }
    30% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-0px);
    }
    70% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-100px);
    }
    90% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-150px);
    }
    99% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-100px);
    }
}

@keyframes xAxis {
    10% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(-100px) rotateZ(20deg)
    }
    20% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(-0px)  rotateZ(60deg)
    }
    30% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(90px)  rotateZ(90deg);;
    }
    70% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(130px)  rotateZ(120deg);
    }
    80% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(70px)  rotateZ(190deg);
    }
    90% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(100px)  rotateZ(330deg);
    }
    99% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(110px)  rotateZ(0deg);
    }
}
.plane-container {
    position: relative;
    width: 300px;
    height: 200px;
    margin-top: 9%;
    margin-left: auto;
    margin-right: auto;
}
.plane {
    position: absolute;
    transform: translate(-50%, -50%) rotate(180deg);
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px auto;
    background-image: url($BeeLogo);
    animation: path 6s linear infinite, turn 6s ease infinite;
  }
  
  @keyframes path {
    0%,
    100% {
      left: 0;
      top: 0;
    }
    25% {
      left: 100%;
      top: 0;
    }
    50% {
      left: 100%;
      top: 100%;
    }
    75% {
      left: 0;
      top: 100%;
    }
  }
  
  @keyframes turn {
    0%,
    24% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    25%,
    49% {
      transform: translate(-50%, -50%) rotate(270deg);
    }
    50%,
    74% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    75%,
    99% {
      transform: translate(-50%, -50%) rotate(90deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

.bee {
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px auto;
    background-image: url($BeeLogo);
}

.flier {
	pointer-events: none;
}

.flier > * {
    animation: fly 20s linear infinite;
    pointer-events: none !important;
	top: 0;
	left: 0;
	transform: translateX(-120%) translateY(-120%) rotateZ(0);
	position: fixed;
	animation-delay: .1s;
    opacity: 1;
    z-index: 10;
}

@keyframes fly {

	98.001%, 0% {
                display: block;
		transform: translateX(-200%) translateY(100vh) rotateZ(0deg)
	}

	15% {
		transform: translateX(100vw) translateY(-100%) rotateZ(180deg)
	}

	15.001%, 18% {
		transform: translateX(100vw) translateY(-30%) rotateZ(0deg)
	}

	40% {
		transform: translateX(-200%) translateY(3vh) rotateZ(-180deg)
	}

	40.001%, 43% {
		transform: translateX(-200%) translateY(-100%) rotateZ(-180deg)
	}

	65% {
		transform: translateX(100vw) translateY(50vh) rotateZ(0deg)
	}

	65.001%, 68% {
		transform: translateX(20vw) translateY(-200%) rotateZ(180deg)
	}

	95% {
		transform: translateX(10vw) translateY(100vh) rotateZ(0deg)
	}
}


.please-wait {
    width: 66px;
    height: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url($BeeLogo);
    animation-name: ckw;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    opacity: .75;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    margin-top: 14px;
}

.please-wait-content {
    vertical-align: middle;
    display: inline-block;
    font-size: 2em;
    font-weight: bolder;
    opacity: .75;
    z-index: 10;
}

.please-wait-content-mini {
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.meal-order-view-raised-deck {
    @include raised-deck();
    padding: 1em;
    margin-left: .5em;
    margin-right: .5em;
}

.meal-order-view__portal {
    z-index: 10;
    position: fixed !important;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
    padding: 2em;
    @include raised-deck();
    border: .35em solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgb(240, 255, 240) 0%, rgb(178, 236, 224) 70%, rgb(160, 175, 238) 100%); 
}

.activity-log__portal {
    @include raised-deck();
    border: .35em solid rgba(255,255,255,1);
    background: linear-gradient(180deg, rgb(240, 255, 240) 0%, rgb(178, 236, 224) 70%, rgb(160, 175, 238) 100%); 
    z-index: 10;
    position: fixed !important;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
    padding: 2em;
}

.activity-log-history {
    @include raised-deck();
    padding: 1em;
    margin-left: .5em;
    margin-right: .5em;
}

.activity-log {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    gap: 1em;
}

.view-activity-log {
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.menu-card-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}
.copy-orders-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-orders-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-dietaries-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-food-temp-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-library-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-menu-pdf-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-privacy-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-password-raised-deck {
    @include raised-deck();
    padding: 2em;
    margin-left: .5em;
    margin-right: .5em;
}

.user-menu-raised-deck {
    @include raised-deck();
    padding: 1.5em;
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-bottom: 1em;
    > .card {
        position: relative;
        height: auto!important;
        margin: 1em 1em;
        width: calc(100% - 2em)!important;
        flex-direction: column;
        max-width: 900px;
        min-height: 0;
        background: #fff;
        word-wrap: break-word;
    }
}

.ui.stackable.one.cards.user-menu {
    justify-content: center;
}

.ui.basic.label {
    background-color: rgba(178, 236, 224, .2);
}
.ui.tiny.basic.label {
    background-color: rgba(178, 236, 224, .45);
}

.runner-label-drag {
    background-color: rgba(241, 241, 235, 0.062) !important;
}

.runner-label-blue {
    background-color: blue !important;
}

.runner-label-gold {
    background-color: #FFD700 !important;
}

.runner-label-green {
    background-color: #7FFF00 !important;
}

.runner-label-grey {
    background-color: #A9A9A9 !important;
}

.runner-label-pink {
    background-color: #FDD7E4 !important;
}

.runner-label-purple {
    background-color: #8A2BE2 !important;
}

.runner-label-red {
    background-color: red !important;
}

.runner-label-white {
    background-color: white !important;
}

.runner-label-yellow {
    background-color: rgb(238, 238, 144) !important;
}

///////////////////////
 


.runner-bar-blue {
    @include runner-bar();
    background-color: blue;
}

.runner-bar-gold {
    @include runner-bar();
    background-color: #FFD700;
}

.runner-bar-green {
    @include runner-bar();
    background-color: #7FFF00;
}

.runner-bar-grey {
    @include runner-bar();
    background-color: #A9A9A9;
}

.runner-bar-pink {
    @include runner-bar();
    background-color: #FDD7E4;
}

.runner-bar-purple {
    @include runner-bar();
    background-color: #8A2BE2;
}

.runner-bar-red {
    @include runner-bar();
    background-color: red;
}

.runner-bar-white {
    @include runner-bar();
    background-color: white;
}

.runner-bar-yellow {
    @include runner-bar();
    background-color: rgb(238, 238, 144);
}

.runner-cell-blue {
    @include runner-cell();
    background-color: blue;
}

.runner-cell-gold {
    @include runner-cell();
    background-color: #FFD700;
}

.runner-cell-green {
    @include runner-cell();
    background-color: #7FFF00;
}

.runner-cell-grey {
    @include runner-cell();
    background-color: #A9A9A9;
}

.runner-cell-pink {
    @include runner-cell();
    background-color: #FDD7E4;
}

.runner-cell-purple {
    @include runner-cell();
    background-color: #8A2BE2;
}

.runner-cell-red {
    @include runner-cell();
    background-color: red;
}

.runner-cell-white {
    @include runner-cell();
    background-color: white;
}

.runner-cell-yellow {
    @include runner-cell();
    background-color: rgb(238, 238, 144);
}


.runner-title-compact {
    text-align:center;
    font-weight: 900;
}

h1.title-left {
    text-align:left;
    padding:0;
    margin:0;
    position:absolute;
}

h1.title-center {
    text-align:center;
}

h1.title-right {
    text-align:right;
}

h2.title-left {
    text-align:left;
    padding:0;
    margin:0;
    position:absolute;
}

h2.title-center {
    text-align:center;
}

h2.title-right {
    text-align:right;
}

h3.title-left {
    text-align:left;
    padding:0;
    margin:0;
    position:absolute;
}

h3.title-center {
    text-align:center;
}

h3.title-right {
    text-align:right;
}

h4.title-left {
    text-align:left;
    padding:0;
    margin:0;
    position:absolute;
}

h4.title-center {
    text-align:center;
}

h4.title-right {
    text-align:right;
}


.label-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.label-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 34mm;
    width: 63mm;
    min-width: 63mm;
    max-width: 63mm;
    text-align: center;
}
.label-icon {
    vertical-align: middle;
    min-width: 90px;
    max-width: 90px;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url($LabelLogo);
    background-position: center center;
    justify-self: center;
}
.label-kite {
    width: 25mm;
    min-width: 25mm;
    max-width: 25mm;
    height: 13mm;
    text-align: center;
    border-radius: 50%;
    border: 2px solid black;
    justify-self: center;
    align-self: center;
    font-weight: 900;
    word-spacing: 9999rem;
    padding-top: 2px;
}
.label-image-kite-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 31mm;
}

.label-text__client {
    @include label-text();
    color: black;
    grid-column: span 2;
    align-self: center;
}
.label-text__meal-dietary--vegetarian_meal {
    @include label-text();
    font-size: 2.6em;
    width: 100vw;
    color: rgb(36, 177, 31);
}
.label-text__meal-dietary--main_dietary {
    @include label-text();
    font-size: 2.6em;
    width: 100vw;
    color: rgb(221, 67, 67);
}
.label-text__meal-dietary--vegan_dietary {
    @include label-text();
    font-size: 2.6em;
    width: 100vw;
    color: rgb(243, 236, 169);
}
.label-text__meal-dietary--halal_dietary {
    @include label-text();
    font-size: 2.6em;
    width: 100vw;
    color: rgb(84, 177, 240);
}
.label-text__meal-dietary--special_dietary {
    @include label-text();
    font-size: 2.6em;
    width: 100vw;
    color: rgb(145, 69, 148);
}
.label-text__meal-dietary--side_alternative {
    @include label-text();
    font-size: 2.6em;
    width: 100vw;
    color: rgb(221, 161, 71);
}
// For printing Labels - may need to adjust
@page { 
	size: A4 portrait;
    margin: 12mm 0 0 6mm; 
}

.page-divide {
    break-after: always;
    -webkit-region-break-after: always;
    page-break-after: always;
}
.page-break {
    break-after: always;
    -webkit-region-break-after: always;
    page-break-after: always;
}

.item-Yellow {background: #ffee58 !important; color: black!important; }
.item-Green {background: #24b11f!important; color: white!important; }
.item-Purple {background: #673ab7!important; color: white!important; }
.item-Red {background: #e53935!important; color: white!important; }
.item-Orange {background: #ffb300!important; color: black!important; }
.item-Pink {background: #f06292!important; color: black!important; }
.item-Blue {background: #29b6f6!important; color: black!important; }

.tag{
    display: inline-block;
    background: #aaa;
    border-radius: 3px;
    padding: 0px 5px;
    margin: 3px;

    & button {
        border: 0;
        background: transparent;
    }
}

@media print {

    .app {
        padding: 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    h1.title-left {
        text-align:left;
        padding:0;
        margin:0;
        position:absolute;
    }
    
    h1.title-center {
        text-align:center;
    }
    
    h1.title-right {
        text-align:right;
    }
    
    
    h3.title-left {
        text-align:left;
        padding:0;
        margin:0;
        position:absolute;
    }
    
    h3.title-center {
        text-align:center;
    }
    
    h3.title-right {
        text-align:right;
    }
    
    h4.title-left {
        text-align:left;
        padding:0;
        margin:0;
        position:absolute;
    }
    
    h4.title-center {
        text-align:center;
    }
    
    h4.title-right {
        text-align:right;
    }

    .runner-bar-blue {
        @include runner-bar();
        background-color: blue;
    }
    
    .runner-bar-gold {
        @include runner-bar();
        background-color: #FFD700;
    }
    
    .runner-bar-green {
        @include runner-bar();
        background-color: #7FFF00;
    }
    
    .runner-bar-grey {
        @include runner-bar();
        background-color: #A9A9A9;
    }
    
    .runner-bar-pink {
        @include runner-bar();
        background-color: #FDD7E4;
    }
    
    .runner-bar-purple {
        @include runner-bar();
        background-color: #8A2BE2;
    }
    
    .runner-bar-red {
        @include runner-bar();
        background-color: red;
    }
    
    .runner-bar-white {
        @include runner-bar();
        background-color: white;
    }
    
    .runner-bar-yellow {
        @include runner-bar();
        background-color: rgb(238, 238, 144);
    }

    .runner-cell-blue {
        @include runner-cell();
        background-color: blue;
    }
    
    .runner-cell-gold {
        @include runner-cell();
        background-color: #FFD700;
    }
    
    .runner-cell-green {
        @include runner-cell();
        background-color: #7FFF00;
    }
    
    .runner-cell-grey {
        @include runner-cell();
        background-color: #A9A9A9;
    }
    
    .runner-cell-pink {
        @include runner-cell();
        background-color: #FDD7E4;
    }
    
    .runner-cell-purple {
        @include runner-cell();
        background-color: #8A2BE2;
    }
    
    .runner-cell-red {
        @include runner-cell();
        background-color: red;
    }
    
    .runner-cell-white {
        @include runner-cell();
        background-color: white;
    }
    
    .runner-cell-yellow {
        @include runner-cell();
        background-color: rgb(238, 238, 144);
    }

    body { 
        -webkit-print-color-adjust: exact;
        overscroll-behavior-y: contain;
        float: none;
    }

    .dont-show-this-unless-printing {
        visibility: visible;
    }

    .ui.basic.segment.dont-show-this-unless-printing {
        visibility: visible;
    }

    // if overflow-x is hidden it will stop page breaks from working
    .pushable {
        overflow-x: visible;
    }
    // more visible font in meta sections of cards
    .ui.card .meta, .ui.cards>.card .meta {
        font-size: 1em;
        color: rgba(0,0,0,1);
    }

    .print-this {
        visibility: visible;
    }

    .print-this-transform-top {
        visibility: visible;
        //transform: translateY(-50%);
    }
           
    .page-divide {
        break-after: always;
        -webkit-region-break-after: always;
        page-break-after: always;
    }
    
    .page-break {
        break-after: always;
        -webkit-region-break-after: always;
        page-break-after: always;
    }
    
    tr {
        break-inside: avoid;
    }

    .ui.table {
        break-inside: avoid;
    }

    .ui.card {
        break-inside: avoid;
    }
    .ui.one.cards {
        break-inside: avoid;
    }

    body > *:not(.print-this) {
        visibility: hidden;
        position: relative;
        height: 0px;
        margin: 0;
        padding: 0;
        overscroll-behavior-y: contain;
    }
}