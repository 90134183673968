.user-form {
    border-radius: 1em;
    padding: 1em;
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);
    width: $LoginViewWidth;
    height: $LoginViewHeight;
    & > .user-form__icon {
        width: 500px;
        height: 100%;
        min-height: 100px;
        min-width: 230px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($Fruits);
    } 
}

.ui.modal:not(.compact) {
    width: calc(100% - 50%) !important;
    // margin-left: 25% !important;
    // height: calc(100% - 65%) !important;
    // margin-top: 12.5% !important;
}