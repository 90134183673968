.login-form {
    @include raised-deck();
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);
    width: $LoginViewWidth;
    height: $LoginViewHeight;
    min-height: 700px;
    text-align: center;

    & > .login-form__icon {
        height: $LoginViewIconHeight;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url($MainLogo);
    } 

    & > .login-form__bee {
        height: $LoginViewBeeHeight;
        max-height: 250px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url($BeeLogo);
    } 

    .login-form__version {
        margin-top: 1.5em;
    }
}

