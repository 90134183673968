// #root > div > div:nth-child(3) > div.ui.segment.active.tab > div > div.ui.segment.active.tab > div > div.ui.one.column.grid > div:nth-child(1) > div > div.log-view__header > div.DayPickerInput > input

div.DayPickerInput > input.single-date {
    height: 35.6px;
    padding-left: 11px;
    width: 100px;
    border-style: solid;
    border-color: rgba(34,36,38,.15);
    border-width: 1px;
    border-radius: 3px;
}

div.DayPickerInput > input.order-entry-single-date {
    height: 28px;
    padding-left: 16px;
    width: 100px;
    border-style: solid;
    border-color: rgba(34,36,38,.15);
    border-width: 1px;
    border-radius: 3px;
    line-height: 1;
    font-size: .85714286rem;
    font-weight: bold;
    
}

.single-date-wrapper {
    position: relative;
}
  
.nudge-left-single-date-overlay {
    position: absolute;
    left: -98px;
    top: 0;
    z-index: 5;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.nudge-right-single-date-overlay {
    position: absolute;
    right: -100px;
    top: 0;
    z-index: 5;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

// https://react-day-picker.js.org/examples/input-custom-overlay/

// .DayPicker-NavButton--prev {
//     margin-right: 1.5em;
//     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
// }
  
// .DayPicker-NavButton--next {
//     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
// }

.date-range-container-flex {
    display: grid;
    grid-gap: 4px;
    justify-content: space-between;
    grid-template-columns: minmax(88px, 1fr) minmax(88px, 1fr);
}

.date-range-container {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 100px 100px;
    
}

.date-range-container-wider {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 120px 120px;
}

.DayPicker-Day--highlightedMondays:not(.DayPicker-Day--disabled)  {
    background-color: rgb(228, 243, 161);
    color: black;
}

.DayPicker-Day--highlightedOrders:not(.DayPicker-Day--disabled)  {
    background-color: rgb(228, 243, 161);
    color: black;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;
    background: white;
    border: .15em solid rgba(255,255,255,.8);
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.5);
    border-radius: .3em;
  }
