.signature-screen-container {
    max-width: 400px;
    min-height: 342px;
    width: 98%;
    height: 342px;
    align-items: center;
    text-align: center;
    padding-top: 4px;
    background-color: white;
    border-style: solid;
    border-color: #B3D17E;
    border-width: 1px;
    border-radius: 3px;

    margin-left: auto;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
}