$LoginViewWidth: 90vw;
$LoginViewHeight: 80vh;
$LoginViewIconHeight: 36vh;
$LoginViewBeeHeight: 20vh;
$LoginViewIconWidth: 65vw;


$AppViewWidth: 100vw;
$AppViewMinWidth: 500px;
$AppViewHeight: 100vh;
$AppViewIconHeight: 10vh;
$AppViewIconWidth: 25vw;
$AppViewIconMinHeight: 64px;
$AppViewIconMinWidth: 300px;