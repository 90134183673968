a.pdfLink:link { 
    color:black; 
    text-decoration:none; 
    font-weight:bold; 
}

a.pdfLink:visited { 
    color:grey; 
    text-decoration:none; 
    font-weight:bold; 
}

a.pdfLink:hover { 
    color: blue; 
    text-decoration:underline; 
    font-weight:bold; 
}

a.pdfLink:active { 
    color: blue; 
    text-decoration:underline; 
    font-weight:bold; 
}