
@mixin  runner-bar(){
    height: 7px;
    width: 100%;
    border: solid .5px black;
    border-radius: 3px;
}

@mixin  runner-cell(){
    height: 10px;
    margin-right: -1px !important;
    margin-left: 0 !important;
    border-top: solid .5px black !important;
}

@mixin  label-text(){
    vertical-align: middle;
    font-size: 1.5em;
    font-weight: bolder;
}

@mixin raised-deck(){
    background: rgba(255,255,255,0.01);
    border: 1px solid rgba(255,255,255,.2);
    box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.2);
    border-radius: 1.5em;
}