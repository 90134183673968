
.checkAlterRemoveBtn {
    border-radius: 2px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: #dee5ec;
    border: none;
}
.checkAlterRemoveBtn:hover,
.checkAlterRemoveBtn.hover {
    color: white;
    background-color: #346392;
}
.checkAlterRemoveBtn:active,
.checkAlterRemoveBtn.active {
    background-color: #27496d;
}

.checkItem {
    border-radius: 4px;
    border: 1px solid rgba(34,36,38,.15);
    justify-content: center;
    padding: 2px;
    width: 200px;
    max-width: 200px;
    text-align: center;
    background-color: rgba(135, 189, 243, 0.15);
}

.checkContainer {
    border-radius: 4px;
    border: 1px solid rgba(34,36,38,.15);
    padding: 3px;
}

.checkSelectorContainer {
    border-radius: 4px;
    border: 1px solid rgba(34,36,38,.15);
    width: 100%;
    height: 30px;
}

.checkItemsContainer {
    display: flex;
    flex-wrap: wrap;
}

.ui.tiny.basic.button.add-check {
    display: inline-block;
    height: 28px;
    min-height: 1em;
    border-radius: .28571429rem;
    cursor: pointer;
    vertical-align: baseline;
    color: rgba(0,0,0,.6);
    font-family: inherit;
    margin: 0 .25em 0 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 6px;
    padding-right: 6px;
    box-shadow: 0 0 0 1px #2185d0 inset!important;
}

.ui.fluid.dropdown.item-checks > div.text {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    border-top: 1px solid white;
    height: 57px;
    width: 150px;
}

.ui.fluid.dropdown.item-checks > .dropdown.icon:before {
   display: none; 
}


.ui.fluid.dropdown.item-checks {
    padding-top: 0.33em;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    border-radius: .28571429rem;
}
